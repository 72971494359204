import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderSimple from "../components/HeaderSimple";

import "../utils/general.scss";
import "../utils/page-careers.scss";

const InCosaCrediamo = ({ location, pageContext }) => {
    return (
        <Layout pageContext={pageContext}>
            <SEO title={pageContext.localeResources['careers.boxes.first.title']} description="Scopri in cosa crediamo">
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>
            
            <HeaderSimple title={`${pageContext.localeResources['careers.boxes.first.title']}`} subtitle={`${pageContext.localeResources['careers.mainDescription.title']}`} />
            
            <section className="section-text">
                <div className="container section">
                    <div className="text" style={{marginBottom: "30px"}}>
                        <h5 className="subtitle" data-aos="fade-right" data-aos-offset="200">{pageContext.localeResources['inCosaCrediamo.client.title']}</h5>
                        <p className="ie" data-aos="fade-right" data-aos-offset="200" dangerouslySetInnerHTML={{__html: pageContext.localeResources['inCosaCrediamo.client.text']}}></p>
                    </div>
                    <div className="text" style={{marginBottom: "30px"}}>
                        <h5 className="subtitle" data-aos="fade-left" data-aos-offset="200">{pageContext.localeResources['inCosaCrediamo.people.title']}</h5>
                        <p className="ie" data-aos="fade-left" data-aos-offset="200" dangerouslySetInnerHTML={{__html: pageContext.localeResources['inCosaCrediamo.people.text']}}></p>
                    </div>
                    <div className="text">
                        <h5 className="subtitle" data-aos="fade-right" data-aos-offset="300">{pageContext.localeResources['inCosaCrediamo.teamwork.title']}</h5>
                        <p className="ie" data-aos="fade-right" data-aos-offset="300" dangerouslySetInnerHTML={{__html: pageContext.localeResources['inCosaCrediamo.teamwork.text']}}></p>
                    </div>
                    <div className="text">
                        <h5 className="subtitle" data-aos="fade-left" data-aos-offset="300">{pageContext.localeResources['inCosaCrediamo.ethics.title']}</h5>
                        <p className="ie" data-aos="fade-left" data-aos-offset="300" dangerouslySetInnerHTML={{__html: pageContext.localeResources['inCosaCrediamo.ethics.text']}}></p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default InCosaCrediamo
